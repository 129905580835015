@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Catamaran', sans-serif;
}



