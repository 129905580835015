.flex-container {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  top: -0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.flex-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: 20px;
  z-index: 1;
  overflow: hidden;
  align-items: center;
}

h1 {
  top: 0;
  color: white;
  font-size: 1000%;
  width: 90%;
  line-height: 0.6;
  transition: font-size 0.3s ease-in-out, left 0.3s ease-in-out;
  filter: blur(5px);
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  animation: cloudEffect 60s linear infinite;
  text-align: center;
  overflow: hidden;
}

@keyframes cloudEffect {
  0% { background-position: 0 0; }
  50% { background-position: 50px 100px; }
  100% { background-position: 0 0; }
}

.smaller {
  font-size: 40px!important;
  width: 200px;
  text-align: left;
  height: 100%;
  opacity: .8;
  filter: blur(1.5px);
}

.text {
  color: white;
  font-size: 1rem;
  opacity: 1;
  width: 70%;
  z-index: 1;
  margin-top: -50px;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
}

button {
  padding: 20px 100px 20px 100px;
  border-radius: 25px;
  background-color: transparent;
  font-size: 20px;
  border-color: white;
  border-width: 3px;
  color: white;
  opacity: 1;
  margin-top: 30px;
  width: 450px;
}

button:hover {
  opacity: 1;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 255) 10%, rgba(0, 0, 255, 0.3), rgba(0, 0, 255) 90%);
  z-index: 1;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

/* Add media query for responsiveness */
@media (max-width: 768px) {

  .flex-item {
    padding-left: 20px;
    align-items: left;
  }
  
  h1 {
    font-size: 18vw !important;
    left: 0;
    margin-top: -90px;
    padding-bottom: 40px;
    filter: blur(2.5px);
  }

  .text {
    font-size: 0.8rem;
    width: 90%;
  }

  button {
    padding: 15px 50px;
    font-size: 16px;
    width: 70%;
  }
}

.interactive-button {
  display: flex;
  align-items: center;
  padding: 15px 40px;
  border-radius: 30px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  /* box-shadow:  20px 20px 60px #d1d1d1, -20px -20px 60px #ffffff; */
  font-size: 18px;
  border: none;
  color: rgba(0, 0, 255, 0.8); /* Blue color matching the theme */
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 70%; /* Adjust based on preference */
  justify-content: center; /* Center the text and icons */
  margin-top: 20px; /* Space from the paragraph */
}

.interactive-button:hover {
  transform: translateY(-3px);
  box-shadow:  10px 10px 20px #d1d1d1, -10px -10px 20px #ffffff;
}

.interactive-button span:hover{
  color: rgba(0, 0, 255, 1);
}

.interactive-button:active {
  transform: translateY(2px);
  box-shadow:  10px 10px 30px #d1d1d1, -10px -10px 30px #ffffff;
}

.cloud-instruction {
  margin-left: 5px;
  font-weight: 400;
  font-size: 19px; /* Slightly larger than icons for emphasis */
}

.interactive-button i {
  margin-right: 10px;
  font-size: 18px; /* Consistent with the main text for clean design */
}

/* mobile divices */
@media (max-width: 768px) {
  .flex-container{
    touch-action: none; /* Allow panning */
    overflow: hidden; /* Prevent scrolling */
    user-scalable: no; /* Disable user scaling */
    -ms-touch-action: pan-x pan-y; /* Microsoft Edge support */
  }
}
