/* Global Styles */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: url("#flyOn");
  font-family: 'Catamaran', sans-serif;
  height: 100vh;
}

.App, .App .name {
  line-height: 0.8;
}


span {
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  will-change: transform;
}

span:hover {
  opacity: .8;
  /* color: red; */
  z-index: 1;
}

/* Footer */
.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  background-color: blue;
  color: white;
  z-index: 1;
}

.footer a {
  color: white;
}

/* Overlay */
.OverlayContainer {
  position: absolute;
  transform: translate(-70%, -50%);
  left: 50%;
  top: 50%;
  z-index: 100;
}

/* Tabs */
.tabs-container {
  position: absolute;
  right: 30ch;
  z-index: 1000;
  transition: right 0.3s ease-in-out;
}

.tabs-container.open {
  right: 31ch;
}

.tab, .zoomIn, .zoomOut {
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  text-align: center;
  background-color: rgba(0, 0, 255);
  writing-mode: initial;
  transform: none;
  margin-top: 5px;
  border-radius: 50%;
  margin-right: 15px;
}

.tab.active, .tab:hover, .zoomIn:hover, .zoomOut:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.tab span {
  color: white;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  margin-left: 5px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

/* Legend */
.legend-container {
  position: absolute;
  top: 1vh;
  right: -290px;
  z-index: 0;
  transition: right 0.3s ease-in-out;
}

.legend-container.open {
  right: 0;
}

.legend {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30ch;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 2;
  opacity: 0.9;
  color: white;
  font-size: 16px;
  line-height: 1.5;
  background-color: rgba(0, 0, 255, 0.5);
  transition: right 0.2s ease-in-out;
  border-radius: 25px;
}

.legend-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-date-range {
  margin: 0;
  font-size: 15px;
}

.legend-stats, .legend-incidents, .legend-victims {
  margin: 0;
  font-size: 10px;
}

.legend ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.legend li {
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.legend li:last-child {
  border-bottom: none;
}

.legend-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: blue;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.legend-title {
  margin: 0;
  font-size: 18px;
}

/* Close Button */
.close-button {
  background-color: white;
  border: none;
  color: blue;
  font-size: 15px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  right: 15px;
  font-weight: 800;
  width: 20px;
  height: 20px;
}

/* Victim */
.victim-info {
  display: flex;
  align-items: center;
}

.victim-id {
  font-weight: bold;
  margin-right: 5px;
  width: 50px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  line-height: 0.8
}

.legend-content{
  padding: 10px;
}


/* Names Container */
.names-container {
  display: inline-block;
  flex-grow: 1;
  overflow: visible;
  text-overflow: ellipsis;
  background-clip: text;
  -webkit-background-clip: text;
  width: 500px;
  margin-left: 100px;
  color: white;
  filter: blur(5px);
  opacity: 0.9;
  transition: width 0.5s ease, filter 0.7s ease, text-shadow 0.7s ease;
}

.names-container .name {
  margin: -5px 0; 
  transform: translateY(calc(-50% + var(--random-offset, 0)));
  z-index: var(--z-index, 0);
}

.names-container:not(.blur) {
  filter: blur(0);
}

.names-container span {
  position: relative;
  /* white-space: nowrap; */
}

.names-container.zoomed-in {
  text-shadow: 13px 13px 15px rgb(0, 0, 0);
  opacity: 1;
}

/* Legend Dropdown */
.legend-dropdown {
  height: 200px;
  overflow: scroll;
}

.legend-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.legend-container li {
  padding: 5px;
  cursor: pointer;
}

.legend-container li:hover {
  background-color: #f2f2f2;
}

/* Animations */
@keyframes pulse {
  0%, 100% { color: white; }
  50% { color: red; }
}

@keyframes dance {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes cloudEffect {
  0%, 100% { background-position: 0 0; }
  50% { background-position: 100% 100%; }
}

@keyframes moveCloud {
  0% { transform: translateX(10%); }
  100% { transform: translateX(-10%); }
}

.pulse {
  animation: pulse 1.5s ease-in-out;
  animation-iteration-count: 1;
}
